<template>
<div id="page">
    <div class="p-5"></div>
    <div class="wrapper bg-white">
        <div class="h2 text-center mb-3">
            <img src="@/assets/robot-logo.png" alt="Robot">
        </div>
        <div class="h2 text-center"> Manufacturing</div>
        <!-- <div class="h4 text-muted text-center pt-2">Enter your login details</div> -->
        <div class="form-group py-2">
            <div class="input-field"> <span class="far fa-user p-2"></span> 
            <input v-model="username" type="text" placeholder="Username" required class=""> </div>
        </div>
        <div class="form-group py-1 pb-2">
            <div class="input-field"> 
                <span class="fas fa-lock p-2"></span> 
                <input v-model="password" type="password" placeholder="Password" required class=""> 
                <!-- <button class="btn bg-white text-muted"> <span class="far fa-eye-slash"></span> </button>  -->
            </div>
        </div>
        <div class="d-grid">
        <button id="btn-login" 
                @click="performLogin"
                class="btn text-center my-3 p-2">Log in</button>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            'username': null,
            'password': null
        }
    },

    computed: {
        ...mapGetters('users', ['isUserLoggedIn', 'isUserManager'])
    },

    methods: {
        ...mapActions('users', ['login']),

        async performLogin() {
            const loginData = {'username': this.username, 'password': this.password}
            await this.login(loginData)
            if (this.isUserLoggedIn) {
                if (this.isUserManager) {
                    console.log("User manager");
                    return this.$router.push({ name: 'backoffice-products' })
                } else {
                    console.log("Other users");
                    return this.$router.push({ name: 'checkin' })
                }
            }
        }
    },

    created() {
        console.log(this.getWorkOrdersSelectData);
    },

    beforeCreate () {
    if (this.$store.getters['users/isUserLoggedIn']) {
        if (this.$store.getters['users/isUserManager']) {
            this.$router.push({ name: 'backoffice' })
        } else {
            this.$router.push({ name: 'checkin' })
        }
    } 
}
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins&display=swap');

$main-color: #040c85;
$main-color-hover: #040d85a1;

#page {
    // background-color: #eee;
    background-image:  linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('~@/assets/login-background.jpg');
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    // background: linear-gradient(to top, #fff 10%, rgba(42, 67, 141, 1) 90%) no-repeat
}

.wrapper {
    max-width: 500px;
    border-radius: 10px;
    margin: 0px auto;
    padding: 30px 40px;
    box-shadow: 20px 20px 80px rgb(206, 206, 206)
}

.h2 {
    font-family: 'Kaushan Script', cursive;
    font-size: 3.5rem;
    font-weight: bold;
    color: $main-color;
    font-style: italic
}

.h4 {
    font-family: 'Poppins', sans-serif
}

.input-field {
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid $main-color;
    color: $main-color
}

.input-field:hover {
    color: $main-color-hover;
    border: 1px solid $main-color-hover
}

input {
    border: none;
    outline: none;
    box-shadow: none;
    width: 100%;
    padding: 0px 2px;
    font-family: 'Poppins', sans-serif
}

.fa-eye-slash.btn {
    border: none;
    outline: none;
    box-shadow: none
}

a {
    text-decoration: none;
    color: $main-color;
    font-weight: 700
}

a:hover {
    text-decoration: none;
    color: $main-color-hover
}

.option {
    position: relative;
    padding-left: 30px;
    cursor: pointer
}

.option label.text-muted {
    display: block;
    cursor: pointer
}

.option input {
    display: none
}

.option input:checked~.checkmark:after {
    display: block
}

.option .checkmark:after {
    content: "";
    width: 13px;
    height: 13px;
    display: block;
    background: $main-color;
    position: absolute;
    top: 48%;
    left: 53%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 300ms ease-in-out 0s
}

.option input[type="radio"]:checked~.checkmark {
    background: #fff;
    transition: 300ms ease-in-out 0s;
    border: 1px solid $main-color
}

.option input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1)
}

#btn-login{
    border-radius: 20px;
    background-color: $main-color;
    color: #fff
}

#btn-login:hover {
    background-color: $main-color-hover
}

@media(max-width: 575px) {
    .wrapper {
        margin: 10px
    }
}

@media(max-width:424px) {
    .wrapper {
        padding: 30px 10px;
        margin: 5px
    }

    .option {
        position: relative;
        padding-left: 22px
    }

    .option label.text-muted {
        font-size: 0.95rem
    }

    .checkmark {
        position: absolute;
        top: 2px
    }

    .option .checkmark:after {
        top: 50%
    }

    #forgot {
        font-size: 0.95rem
    }
}
</style>
